/* eslint-disable import/no-anonymous-default-export */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Code,
  Grid,
  Center,
  Square,
  theme,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Switch as CSwitch,
  Wrap,
  WrapItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image as CImage,
  Progress,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Slide,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';

import {
  FaUser,
  FaLock,
  FaTrash,
  FaEdit,
  FaMapMarkerAlt,
  FaImage,
  FaHome,
  FaPlay,
  FaPause,
} from 'react-icons/fa';

import { AiOutlinePlus } from 'react-icons/ai';
import { GiFilmStrip, GiTimeSynchronization } from 'react-icons/gi';
import { GrMenu } from 'react-icons/gr';
import { MdLabel } from 'react-icons/md';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import MainStore from '../stores/MainStore';

import Files from 'react-files';
import ReactPlayer from 'react-player';

// import myVideo from '../video.mp4';

import captureVideoFrame from 'capture-video-frame';

function msToTime(s, d = 3) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, d);
}

export default () => {
  let { path } = useRouteMatch();

  const handleTabClosing = () => {
    alert('WOAH THERE');
  };

  const alertUser = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleTabClosing);
    };
  }, []);

  return (
    <Box>
      <PlacementExample />
      <Switch>
        <Route exact path={path}>
          <VideoMain />
        </Route>
        <Route path={`${path}/:packageID`}>
          <VideoMain />
        </Route>
        <Route path={`${path}/something`}>
          <Text>Something!</Text>
        </Route>
      </Switch>
    </Box>
  );
};

const VideoMain = observer(() => {
  let history = useHistory();

  let { packageID } = useParams();

  const store = useContext(MainStore);

  const player = useRef(null);
  const prevTime = useRef(0);
  // const proj = useRef();

  const [video, setVideo] = useState();
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [duration, setDuration] = useState(0);
  const [pos, setPos] = useState(0);
  const [posText, setPosText] = useState('');
  const [markers, setMarkers] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState({
    content: 'None',
    selected: [],
  });
  const [easyMode, setEasyMode] = useState(false);

  const [proj, setProj] = useState();
  const [projName, setProjName] = useState('Untitled');
  const [showIP, setShowIP] = useState(false);
  const [elapsed, setElapsed] = useState('00:00:00:000');

  const [sub, setSub] = useState();
  const [subFile, setSubFile] = useState();

  useEffect(() => {
    if (packageID && !videoFilePath) {
      // console.log('IS LOADING FROM CMS?', packageID);

      // proj.current = store._getPackage(packageID);

      const p = store._getPackage(packageID);
      console.log('Loading Package:', toJS(p));

      setProj(p);
      if (p) {
        setProjName(p.title);
      }

      if (p && p.subtitle) {
        console.log('SUB?', p.subtitle);
        anyNameFunction();
      } else {
        if (p && p.video) setVideoFilePath(p.video.url);
      }

      // Create an scoped async function in the hook
      async function anyNameFunction() {
        await store
          ._srtToVtt(p.subtitle.url)
          .then(blob => blob.getURL())
          .then(url => {
            console.log('URL', url);
            setSub(url);
            if (p && p.video) setVideoFilePath(p.video.url);
          });
      }
      // Execute the created function directly

      // console.log(proj.current);

      //set the states
      // if (proj.current) setVideoFilePath(proj.current.video.url);
      // if (p && p.video) setVideoFilePath(p.video.url);
    }
    if (packageID && videoFilePath) {
      console.log('Awaited?', sub);

      //process the markers
      // const ips = proj.current.interaction_points.map(ip => {
      const ips = proj.interaction_points.map(ip => {
        const newPos = (ip.pause_at_ms / (duration * 1000)) * 100;
        //get index of first \t
        if (ip.content == null) ip.content = '';

        const splitPoint = ip.content.indexOf('\t');

        const content = ip.content.substring(0, splitPoint - 1);
        const selected = ip.content.substring(splitPoint).split('\t');
        selected.shift();

        const stickers = ip.Stickers.map(s => {
          const sticker = { ...s };
          sticker.image = { ...s.image };
          return sticker;
        });

        return {
          ms: ip.pause_at_ms,
          timestamp: msToTime(ip.pause_at_ms),
          pos: newPos,
          content,
          selected,
          pause_point: ip.pause_point,
          stickers,
        };
      });

      setMarkers(ips);
    }

    // console.log(videoFilePath);
    // setVideoFilePath(myVideo);

    return () => {};
  }, [duration]);

  const resetAll = () => {
    // console.log('reset!');
    setVideo(null);
    setVideoFilePath(null);
    setIsPlaying(false);
    setMarkers([]);
    setPos(0);
  };

  const reloadData = data => {
    // console.log('RELOAD!', data);
    setProj(store._getPackage(data.data.id));
    history.replace(`/edit/${data.data.id}`);
  };

  const sortByTime = array => {
    return array.sort((a, b) => a.ms - b.ms);
  };

  const onFilesChange = files => {
    // console.log(files);

    // console.log(JSON.stringify(files[0]));

    store._globalToast({
      title: 'Video loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    // console.log('PATH', URL.createObjectURL(files[0]));
    setVideo(files[0]);
    setVideoFilePath(URL.createObjectURL(files[0]));
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  const onSeek = data => {
    // console.log('seek', data);
    // setPos(data);
  };

  const onDuration = data => {
    setDuration(data);
    // console.log('duration', data);
  };

  const onProgress = data => {
    // console.log('progress', data, '/', duration);
    const position = data.played * 100;

    setElapsed(msToTime(data.playedSeconds * 1000, 2));

    // console.log('POS:', position);
    if (!dragging) setPos(position);

    if (previewMode && !dragging) {
      //loop through all markers and check if need to interact
      const pos = Math.floor(data.playedSeconds * 1000);

      markers.map((ip, i) => {
        const sec = Math.floor(ip.ms);
        if (sec >= prevTime.current && sec < pos) {
          console.log('HIT!!!', prevTime.current, sec, pos);

          if (pos - prevTime.current < 200) {
            if (easyMode && ip.pause_point) {
              setIsPlaying(false);
            } else if (!ip.pause_point) {
              setIsPlaying(false);
              setPreviewData({ data: ip, index: i });
              setPreviewOpen(true);
            }
          }
        }
      });

      prevTime.current = pos;
    }
  };

  const onDragEnd = value => {
    const getSec = duration * (value / 100);

    console.log('onChange', value, getSec);

    // setPos(value);
    player.current.seekTo(getSec, 'seconds');
    setDragging(false);
    setPosText('');
  };

  return (
    <Box borderTop="1px solid black">
      {!store.alertClosed && (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle mr={2}></AlertTitle>
          <AlertDescription>
            Please make sure that you have 3 Pause Points between Interaction
            Points before exporting.
          </AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => store._hideAlert()}
          />
        </Alert>
      )}
      <Flex
        backgroundColor="#ffffff"
        boxShadow="sm"
        px={'10px'}
        py={'6px'}
        alignItems="center"
      >
        <Box as={Link} to="/"></Box>
        <Menu>
          <MenuButton
            as={Box}
            cursor="pointer"
            boxShadow="base"
            // border="1px solid black"
            // border="1px solid black"
          >
            <Icon
              as={GrMenu}
              backgroundColor="#87c94e"
              w={6}
              h={6}
              p="2px"
            ></Icon>
          </MenuButton>
          <MenuList>
            <MenuItem color="black" onClick={() => setShowIP(!showIP)}>
              {`${showIP ? 'Hide' : 'Show'} Interaction Points`}
            </MenuItem>
            <Files
              className="files-dropzone"
              onChange={files => {
                console.log(files);
                store
                  ._srtFileToVtt(files[0])
                  .then(blob => blob.getURL())
                  .then(url => {
                    const vp = videoFilePath;
                    setVideoFilePath(null);
                    setSub(url);
                    setVideoFilePath(vp);
                    setSubFile(files[0]);
                  });
              }}
              accepts={['.srt']}
              multiple={false}
              clickable
            >
              <MenuItem>Load Subtitle (.srt)</MenuItem>
            </Files>
            <MenuDivider />
            <AlertPopup
              title="Back to Home?"
              onComplete={() => {
                history.replace('/');
              }}
            >
              <MenuItem color="red">Back to Home</MenuItem>
            </AlertPopup>
          </MenuList>
        </Menu>
        <Text ml={4}>{projName}</Text>
        {/* <Files
          className="files-dropzone"
          onChange={files => {
            console.log(files);
            store
              ._srtFileToVtt(files[0])
              .then(blob => blob.getURL())
              .then(url => {
                const vp = videoFilePath;
                setVideoFilePath(null);
                setSub(url);
                setVideoFilePath(vp);
                setSubFile(files[0]);
              });
          }}
          accepts={['.srt']}
          multiple={false}
          clickable
        >
          <Button colorScheme="blue">Load SRT</Button>
        </Files> */}
      </Flex>

      <Flex height="400px">
        {showIP && (
          <Box
            flex="1"
            pr={'20px'}
            // w={'100%'}
            overflowY="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'grey',
                borderRadius: '24px',
              },
            }}
          >
            <IPList markers={[...markers]} setMarkers={setMarkers} />
          </Box>
        )}
        <VStack flex="2" backgroundColor="#232323">
          <Box w="100%" h="360px">
            {videoFilePath == null ? (
              <Center h="100%" color="white">
                Video not available
              </Center>
            ) : (
              <>
                {/* {sub && ( */}
                <ReactPlayer
                  ref={player}
                  url={videoFilePath}
                  controls={false}
                  progressInterval={1}
                  width="100%"
                  height="100%"
                  playing={isPlaying}
                  onSeek={onSeek}
                  onDuration={onDuration}
                  onProgress={onProgress}
                  onEnded={() => setIsPlaying(false)}
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: 'anonymous',
                      },
                      tracks: [
                        {
                          kind: 'subtitles',
                          src: sub,
                          srcLang: 'en',
                          default: true,
                        },
                      ],
                    },
                  }}
                />
                {/* )} */}
              </>
            )}
          </Box>
          <Icon
            as={isPlaying ? FaPause : FaPlay}
            color="white"
            onClick={() => setIsPlaying(!isPlaying)}
            cursor="pointer"
          />
        </VStack>
      </Flex>

      <Box>
        <Flex p="8px" borderTop="5px solid #939393">
          <Text fontSize="24px" color="#92cc61">
            {elapsed}
          </Text>
        </Flex>
        <HStack spacing={0} mt="20px">
          <Box w="120px" height="64px" boxShadow="lg">
            <Center h="100%">
              <Icon as={GiFilmStrip} w="24px" h="24px" color="#b5b5b5" />
            </Center>
          </Box>
          <Slider
            overflowX="clip"
            isDisabled={videoFilePath == null ? true : false}
            // mt={'60px'}
            // mb={'30px'}
            aria-label="slider-ex-1"
            focusThumbOnChange={false}
            step={0.1}
            onChangeStart={value => {
              setPos(undefined);
              setDragging(true);
            }}
            onChange={value => {
              const dur = duration * (value / 100) * 1000;
              setPosText(msToTime(dur));
            }}
            onChangeEnd={onDragEnd}
            value={pos}
          >
            <SliderTrack
              height={'60px'}
              backgroundColor="#939393"
              borderTop="10px solid #b5b5b5"
              borderBottom="10px solid #b5b5b5"
            >
              {/* <SliderFilledTrack backgroundColor="#b5b5b5" /> */}
            </SliderTrack>
            <SliderThumb boxSize={1}>
              <Box
                width={'20px'}
                height={'70px'}
                backgroundColor="#00fcff"
              ></Box>
              <Text
                borderRadius="full"
                px={'10px'}
                pos="absolute"
                top={'-50px'}
                left={'5px'}
                backgroundColor="rgba(255,255,255,0.5)"
              >{`${posText}`}</Text>
            </SliderThumb>
            <TimelineSnaps url={videoFilePath} intervals={10} />
            <TimelineMarkers duration={duration} intervals={30} steps={5} />
            <Box pos="relative">
              {markers.map((ip, i) => {
                return (
                  <Box
                    key={`m${i}`}
                    pos="absolute"
                    top={'-30px'}
                    left={`${ip.pos}%`}
                    backgroundColor={ip.pause_point ? '#0000ff' : '#baff00'}
                    opacity={!easyMode && ip.pause_point ? 0.05 : 1}
                    width={'2px'}
                    height={'60px'}
                    onClick={() => {
                      console.log('Marker' + i);
                      setPosText('');
                    }}
                  >
                    <Box
                      pos="absolute"
                      zIndex="99"
                      top={'-18px'}
                      left={'-9px'}
                      w={'80px'}
                    >
                      <Icon
                        as={MdLabel}
                        opacity={0.75}
                        color={ip.pause_point ? '#0000ff' : '#baff00'}
                        w="20px"
                        h="20px"
                        transform="rotate(90deg)"
                      />
                      <Text
                        // display="none"
                        zIndex={99}
                        borderRadius="full"
                        // backgroundColor="#939393"
                        px={'4px'}
                        pos="absolute"
                        top={'2px'}
                        left={'20px'}
                        fontSize={'12px'}
                        fontWeight={700}
                        backgroundColor="rgba(255,255,255,0.5)"
                      >{`IP: ${i}`}</Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Slider>
        </HStack>
        <Flex justifyContent="center" mt={4}>
          <HStack spacing={4}>
            <Text>Easy Mode</Text>
            <CSwitch
              // isDisabled={isPlaying}
              size="lg"
              onChange={e => setEasyMode(e.target.checked)}
            />
            <Text>Preview</Text>
            <CSwitch
              // isDisabled={isPlaying}
              size="lg"
              onChange={e => setPreviewMode(e.target.checked)}
            />
            <InteractionPopup
              title={`CREATE INTERACTION`}
              placeholder="E.g. The sleepy frog was awaken by the alarm clock"
              onComplete={data => {
                const dur = duration * (pos / 100) * 1000;

                console.log('NEW:', data, dur / 1000);

                setMarkers(
                  sortByTime([
                    ...markers,
                    {
                      ms: dur,
                      timestamp: msToTime(dur),
                      pos,
                      content: data.content,
                      selected: data.selected,
                      pause_point: data.pause_point,
                      stickers: data.stickers,
                    },
                  ])
                );
              }}
            >
              <Button
                borderRadius={0}
                background="#87c94e"
                px={'30px'}
                // _hover={{ opacity: 0.75 }}
                onClick={() => setIsPlaying(false)}
              >
                ADD INTERACTION
              </Button>
            </InteractionPopup>

            {packageID ? (
              <ExportPopup
                title="Update Package"
                data={{ video, markers, proj, subtitle: subFile }}
                onComplete={() => {
                  setProj(store._getPackage(packageID));
                }}
                edit={true}
              >
                <Button
                  borderRadius={0}
                  background="#87c94e"
                  px={'30px'}
                  // _hover={{ opacity: 0.75 }}
                >
                  UPDATE
                </Button>
              </ExportPopup>
            ) : (
              <ExportPopup
                title="Export Package"
                data={{ video, markers, projName, subtitle: subFile }}
                onComplete={res => reloadData(res)}
              >
                <Button
                  borderRadius={0}
                  background="#87c94e"
                  px={'30px'}
                  // _hover={{ opacity: 0.75 }}
                >
                  EXPORT
                </Button>
              </ExportPopup>
            )}
          </HStack>
        </Flex>
      </Box>

      {/* <Snapshots url={videoFilePath} /> */}

      <PreviewPopup
        title="Preview"
        isOpen={previewOpen}
        onClose={() => {
          setPreviewOpen(false);
          setIsPlaying(true);
        }}
        data={previewData}
      ></PreviewPopup>
      {!packageID && (
        <NewPopup
          title="New Project"
          onComplete={data => {
            setVideo(data.video);
            setVideoFilePath(URL.createObjectURL(data.video));
            setProjName(data.content);
            console.log('new?', data);
          }}
        />
      )}
    </Box>
  );
});

const Snapshots = ({ url }) => {
  const player = useRef();
  const [img, setimg] = useState([]);

  useEffect(() => {
    return () => {};
  }, [url]);

  const onSeek = data => {
    // console.log('On Seek', data);
    getFrame();
  };

  const onDuration = data => {
    // console.log('PLAYER READY?');
    for (var i = 0; i < 10; i++) {
      const t = i * 10;
      // console.log('SEEKING TO', t);
      setTimeout(() => {
        player.current.seekTo(t);
      }, i * 1000);
    }
  };

  const getFrame = () => {
    const frame = captureVideoFrame(player.current.getInternalPlayer());
    // console.log(frame.dataUri);
    setimg([...img, frame.dataUri]);
  };

  return (
    <Box pos="relative">
      <Box display="none">
        <ReactPlayer
          ref={player}
          url={url}
          controls={true}
          onSeek={onSeek}
          onDuration={onDuration}
          config={{
            file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
            },
          }}
        />
      </Box>
      <HStack>
        <Button onClick={getFrame}>Screenshot!</Button>
        {img.map((image, i) => {
          return <CImage h="100px" objectFit="cover" src={image} />;
        })}
      </HStack>
    </Box>
  );
};

const TimelineSnaps = ({ url, intervals }) => {
  const player = useRef();
  const [markers, setMarkers] = useState([]);
  const [images, setImages] = useState([]);
  const [done, setDone] = useState(true);

  const onSeek = data => {
    getFrame();
  };

  const onDuration = duration => {
    const time = [];
    const mainMarkers = duration / intervals;
    for (var i = 0; i < mainMarkers; i++) {
      const t = i * intervals;
      var timeObj = {
        time: t,
        pos: (t / duration) * 100,
        label: msToTime(t * 1000, 2),
      };
      time.push(timeObj);

      setTimeout(() => {
        if (player && player.current) {
          player.current.seekTo(t);
        }
      }, i * 1000);
    }
    setMarkers(time);
  };

  const getFrame = () => {
    const frame = captureVideoFrame(
      player.current.getInternalPlayer(),
      'jpeg',
      0.1
    );

    setImages([...images, frame.dataUri]);
  };

  return (
    <Box pos="relative" pointerEvents="none">
      <Box display="none">
        <ReactPlayer
          ref={player}
          url={url}
          controls={true}
          onSeek={onSeek}
          onDuration={onDuration}
          config={{
            file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
            },
          }}
        />
      </Box>
      {done &&
        markers.map((m, i) => {
          return (
            <Box key={`t${i}`} pos="absolute" top={'-20px'} left={`${m.pos}%`}>
              <CImage h="40px" objectFit="cover" src={images[i]} />
            </Box>
          );
        })}
    </Box>
  );
};

const TimelineMarkers = ({ duration, intervals, steps }) => {
  const [markers, setMarkers] = useState([]);
  const [sub, setSub] = useState([]);

  useEffect(() => {
    //compute the duration
    if (duration <= 0) {
      setMarkers([]);
      return () => {};
    }

    const time = [];
    //compute how many 5sec bar
    const mainMarkers = duration / intervals;
    for (var i = 0; i < mainMarkers; i++) {
      const t = i * intervals;
      var timeObj = {
        time: t,
        pos: (t / duration) * 100,
        label: msToTime(t * 1000, 2),
      };
      time.push(timeObj);
    }
    setMarkers(time);

    const subinternal = steps;
    const subMarkers = duration / subinternal;
    const subtime = [];
    for (var j = 0; j < subMarkers; j++) {
      const st = ((j * subinternal) / duration) * 100;
      subtime.push(st);
    }
    setSub(subtime);

    return () => {};
  }, [duration]);

  return (
    <Box pos="relative" pointerEvents="none">
      {sub.map((s, i) => {
        return (
          <Box
            key={`st${i}`}
            pos="absolute"
            top={'-42px'}
            left={`${s}%`}
            backgroundColor="#939393"
            width={'2px'}
            height={'10px'}
          />
        );
      })}
      {markers.map((m, i) => {
        return (
          <Box
            key={`t${i}`}
            pos="absolute"
            top={'-50px'}
            left={`${m.pos}%`}
            backgroundColor="#939393"
            width={'2px'}
            height={'18px'}
          >
            <Box pos="absolute" top={'0px'} left={'0px'} w={'90px'}>
              <Text
                pos="absolute"
                top={'-18px'}
                left={'-45px'}
                fontSize={'12px'}
                fontWeight="bold"
                // backgroundColor="rgba(255,255,255,0.5)"
              >
                {i == 0 ? '' : `${m.label}`}
              </Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

function PlacementExample() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState('left');

  let { path, url } = useRouteMatch();

  return (
    <Box>
      {/* <Button colorScheme="blue" onClick={onOpen}>
        Menu
      </Button> */}
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
          <DrawerBody>
            <Button as={Link} to={`${path}/`} onClick={onClose}>
              Logout
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

const IPList = ({ markers, setMarkers }) => {
  const [rerender, setRerender] = useState(false);

  return (
    <>
      {/* <Text fontWeight={700}>Timestamps</Text> */}
      <Stack p={4}>
        {markers.map((ip, i) => {
          return (
            <Box key={`ip${i}`}>
              <Box>
                <HStack
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                  mb="4px"
                >
                  <InteractionPopup
                    title={`EDIT INTERACTION at ${ip.timestamp}`}
                    placeholder="E.g. The sleepy frog was awaken by the alarm clock"
                    data={{ ...markers[i] }}
                    editMode={true}
                    onComplete={data => {
                      console.log('ON COMPLETE', data);
                      markers[i].content = data.content;
                      markers[i].selected = data.selected;
                      markers[i].pause_point = data.pause_point;
                      markers[i].stickers = data.stickers;
                      setMarkers([...markers]);
                    }}
                    onCancel={() => {
                      //reset view
                      // console.log('cancel?', markers[i]);
                      setRerender(!rerender);
                    }}
                  >
                    <IconButton
                      size="xs"
                      variant="outline"
                      colorScheme="blue"
                      icon={<FaEdit />}
                      // isDisabled={true}
                    ></IconButton>
                  </InteractionPopup>
                  <span>
                    <IconButton
                      size="xs"
                      variant="outline"
                      colorScheme="red"
                      icon={<FaTrash />}
                      onClick={() => {
                        markers.splice(i, 1);
                        setMarkers([...markers]);
                        // setTheArray(oldArray => [...oldArray, newElement]);
                      }}
                    ></IconButton>
                  </span>
                  <Text
                    fontWeight={700}
                    color={ip.pause_point ? '#0000ff' : '#000000'}
                  >{`Interaction Point ${i}: ${
                    ip.pause_point ? '(Pause Point)' : ''
                  }`}</Text>
                </HStack>

                <Text>{`${ip.timestamp}`}</Text>
                {!ip.pause_point && (
                  <>
                    <Text isTruncated>{`Content: ${ip.content}`}</Text>
                    {/* {ip.stickers.map(st => {
                      return <Text>{st.word}</Text>;
                    })} */}
                  </>
                )}
              </Box>
              <Divider orientation="horizontal" />
            </Box>
          );
        })}
      </Stack>
    </>
  );
};

const PreviewPopup = ({
  title,
  children,
  onComplete,
  placeholder,
  data,
  isOpen,
  onClose,
}) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('');
  const [stickers, setStickers] = useState([]);

  useEffect(() => {
    //process content here

    if (isOpen) {
      // console.log(data);
      let sentence = data.data.content;
      const blanks = data.data.selected;
      blanks.map(w => {
        sentence = sentence.replaceAll(w, '_____');
      });
      // console.log('Sentence', sentence);

      setContent(data.data.content);
      setStickers(data.data.stickers);
    } else {
      setContent('');
      setStickers([]);
    }

    return () => {};
  }, [isOpen]);

  return (
    <Modal
      size="xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="#f0f0f0">
        <ModalHeader
          backgroundColor="white"
          px={3}
          py={2}
          fontSize={16}
          fontWeight={400}
        >{`Interaction Point ${data.index}`}</ModalHeader>
        <ModalCloseButton size="sm" />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>EASY and MEDIUM</Tab>
              <Tab>HARD</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Wrap>
                  {/* <Text>{content}</Text> */}
                  {isOpen &&
                    content.split(' ').map((word, i) => {
                      const found = data.data.selected.includes(word);

                      // console.log('FOUND', word);

                      if (found) {
                        return (
                          <WrapItem key={`w${i}`}>
                            <Text
                              opacity={0}
                              _hover={{ opacity: 1 }}
                            >{`${word}`}</Text>
                            <Text pointerEvents={'none'} pos="absolute">
                              {`${word.replace(/[a-zA-Z]/g, '_')}`}
                            </Text>
                          </WrapItem>
                        );
                      }

                      return (
                        <WrapItem key={`w${i}`}>
                          <Text>{`${word}`}</Text>
                        </WrapItem>
                      );
                    })}
                </Wrap>
                <Box mt={4}>
                  <Wrap>
                    {stickers.map((s, i) => {
                      return (
                        <WrapItem key={`pre${i}`}>
                          {s.image ? (
                            <CImage
                              // cursor="pointer"
                              boxSize="60px"
                              objectFit="cover"
                              src={s.image ? s.image.url : ''}
                              alt="Thumbnail"
                              ignoreFallback
                              border="1px solid #87c94e"
                              borderRadius={4}
                            />
                          ) : (
                            <Square
                              boxSize="60px"
                              border="1px solid #87c94e"
                              borderRadius={4}
                              backgroundColor="white"
                              // cursor="pointer"
                              // _hover={{ opacity: 0.8 }}
                            >
                              <Icon
                                as={FaImage}
                                w={'40px'}
                                h={'40px'}
                                color={'gray.500'}
                                // mb={5}
                              />
                            </Square>
                          )}
                        </WrapItem>
                      );
                    })}
                  </Wrap>
                </Box>
              </TabPanel>
              <TabPanel>
                <Center>
                  <Text>Learners will type here</Text>
                </Center>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            borderRadius={0}
            background="#87c94e"
            px={'30px'}
            onClick={onClose}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const InteractionPopup = ({
  title,
  children,
  onComplete,
  placeholder,
  data,
  editMode,
  onCancel,
}) => {
  const store = useContext(MainStore);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('');
  const [selected, setSelected] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [pausePoint, setPausePoint] = useState(false);

  const [charLimit] = useState(98);

  useEffect(() => {
    if (isOpen) {
      if (data) {
        console.log('DATA', toJS(data));
        setContent(data.content);
        setSelected(data.selected);
        setPausePoint(data.pause_point);
        setStickers([...data.stickers]);
        // setStickers(JSON.parse(JSON.stringify(data.stickers)));
      }
    } else {
      setContent('');
      setSelected([]);
      setStickers([]);
    }

    return () => {};
  }, [isOpen]);

  const onFilesChange = (files, index) => {
    console.log(files, index);

    stickers[index].image = { url: files[0].preview.url };
    stickers[index].imageFile = files[0];

    setStickers([...stickers]);

    // console.log(JSON.stringify(files[0]));
    // setPhoto(files[0]);
    // setPhotoURL(files[0].preview.url);
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  const validate = content => {
    if (pausePoint) {
      onComplete({
        content: '',
        selected: [],
        pause_point: true,
        stickers: [],
      });
      return true;
    }

    if (!content || content.length === 0) {
      store._globalToast({
        title: 'Please provide a sentence.',
        description: '',
        status: 'warning',
      });

      return false;
    }

    //check if stickers is at least 2
    if (stickers.length == 2) {
      if (stickers[0].image && stickers[1].image) {
        onComplete({ content, selected, pause_point: pausePoint, stickers });
        return true;
      } else {
        store._globalToast({
          title: "One or more of your stickers don't have an image.",
          description: '',
          status: 'warning',
        });
        return false;
      }
    } else {
      store._globalToast({
        title: 'Please include two stickers with images',
        description: '',
        status: 'warning',
      });

      return false;
    }

    return false;
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        size="xl"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#f0f0f0">
          <ModalHeader
            backgroundColor="white"
            px={3}
            py={2}
            fontSize={16}
            fontWeight={400}
          >{`${title}`}</ModalHeader>
          <ModalCloseButton
            size="sm"
            onClick={() => {
              // console.log('CLOSING', data);
              if (onCancel) onCancel();
            }}
          />
          <ModalBody>
            <FormControl display="flex" alignItems="center">
              <CSwitch
                size="md"
                id="publish"
                mr={'10px'}
                onChange={e => setPausePoint(e.target.checked)}
                isChecked={pausePoint}
              />
              <FormLabel htmlFor="publish" mb="0">
                Pause Point
              </FormLabel>
            </FormControl>
            {!pausePoint && (
              <>
                <Textarea
                  mt={'5px'}
                  backgroundColor="#b5b5b5"
                  placeholder={placeholder}
                  _placeholder={{ color: 'gray.500' }}
                  onChange={e => setContent(e.target.value)}
                  value={content}
                  maxLength={charLimit}
                />
                <Flex justifyContent="flex-end">
                  <Text
                    fontSize={'12px'}
                    color={content.length >= charLimit ? 'red' : 'black'}
                  >{`${content.length}/${charLimit}`}</Text>
                </Flex>
                <Text mt={2}>Choose words to be blank</Text>
                <Box>
                  {content !== '' &&
                    content.split(' ').map((word, i) => {
                      return (
                        <Button
                          key={`b${i}`}
                          mr={2}
                          mt={2}
                          borderRadius={0}
                          background="#87c94e"
                          px={'30px'}
                          onClick={() => {
                            if (stickers.length >= 2) {
                              return;
                            }

                            setSelected([
                              ...selected,
                              word.replace(/[^\w\s]/gi, ''),
                            ]);

                            setStickers([
                              ...stickers,
                              {
                                word: word.replace(/[^\w\s]/gi, ''),
                                imageFile: null,
                              },
                            ]);
                          }}
                        >
                          {`${word.replace(/[^\w\s]/gi, '')}`}
                        </Button>
                      );
                    })}
                </Box>
                {/* <Box>
                  <Text mt={4}>Words to be blank:</Text>
                  {selected.map((w, i) => {
                    return (
                      <Tag
                        mt={2}
                        mr={2}
                        size="lg"
                        key={`s${i}`}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="green"
                      >
                        <TagLabel>{w}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            selected.splice(i, 1);
                            setSelected([...selected]);
                          }}
                        />
                      </Tag>
                    );
                  })}
                </Box> */}
                <Box>
                  <Text mt={4}>Stickers: (2 Required)</Text>
                  <Wrap mt={'5px'}>
                    {stickers.map((s, i) => {
                      return (
                        <WrapItem key={`st${i}`}>
                          <VStack>
                            <Files
                              className="files-dropzone"
                              onChange={files => onFilesChange(files, i)}
                              onError={onFilesError}
                              accepts={['image/*']}
                              multiple={false}
                              // maxFileSize={10000000}
                              // minFileSize={0}
                              clickable
                            >
                              {s.image ? (
                                <CImage
                                  cursor="pointer"
                                  boxSize="60px"
                                  objectFit="cover"
                                  src={s.image ? s.image.url : ''}
                                  alt="Thumbnail"
                                  ignoreFallback
                                />
                              ) : (
                                <Square
                                  boxSize="60px"
                                  border="1px solid #87c94e"
                                  borderRadius={4}
                                  backgroundColor="white"
                                  cursor="pointer"
                                  _hover={{ opacity: 0.8 }}
                                >
                                  <Icon
                                    as={FaImage}
                                    w={'40px'}
                                    h={'40px'}
                                    // mb={5}
                                  />
                                </Square>
                              )}
                            </Files>
                            {/* <Text m={0}>{s.word}</Text> */}
                            <Tag
                              mt={2}
                              mr={2}
                              size="lg"
                              key={`s${i}`}
                              borderRadius="full"
                              variant="solid"
                              colorScheme="green"
                            >
                              <TagLabel>{s.word}</TagLabel>
                              <TagCloseButton
                                onClick={() => {
                                  selected.splice(i, 1);
                                  setSelected([...selected]);

                                  stickers.splice(i, 1);
                                  setStickers([...stickers]);
                                }}
                              />
                            </Tag>
                          </VStack>
                        </WrapItem>
                      );
                    })}
                  </Wrap>
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              borderRadius={0}
              background="#87c94e"
              px={'30px'}
              onClick={() => {
                if (validate(content)) onClose();
                // validate(content);
                // onClose();
              }}
            >
              {editMode ? 'Update' : 'Add'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ExportPopup = ({ title, children, onComplete, data, edit }) => {
  const store = useContext(MainStore);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState();
  const [photoURL, setPhotoURL] = useState('');
  const [label, setLabel] = useState('');
  const [desc, setDesc] = useState('');
  const [published, setPublished] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isOpen) {
      if (data) {
        // console.log(data);
        setLabel(data.projName);
        if (edit) {
          // console.log('EDITING?', toJS(data.proj));
          setPhoto(null);
          setPhotoURL(data.proj.thumbnail.formats.thumbnail.url);
          setLabel(data.proj.title);
          setDesc(data.proj.description);
          setPublished(data.proj.published);
        }
      }
    } else {
      setLoading(false);
      setPhoto(null);
      setPhotoURL('');
      setLabel('');
      setDesc('');
      setProgress(0);
      setPublished(false);
    }

    return () => {};
  }, [isOpen]);

  const onFilesChange = files => {
    // console.log(files);

    // console.log(JSON.stringify(files[0]));

    setPhoto(files[0]);
    setPhotoURL(files[0].preview.url);
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        size="xl"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#f0f0f0">
          <ModalHeader
            backgroundColor="white"
            px={3}
            py={2}
            fontSize={16}
            fontWeight={400}
          >{`${title}`}</ModalHeader>
          <ModalCloseButton size="sm" isDisabled={loading} />
          <ModalBody>
            <Stack spacing={3}>
              <Center>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={['image/*']}
                  multiple={false}
                  // maxFileSize={10000000}
                  // minFileSize={0}
                  clickable
                >
                  {photoURL !== '' ? (
                    <CImage
                      cursor="pointer"
                      boxSize="160px"
                      objectFit="cover"
                      src={photoURL}
                      alt="Thumbnail"
                      ignoreFallback
                    />
                  ) : (
                    <Center
                      backgroundColor="#b5b5b5"
                      w="160px"
                      h="160px"
                      color="black"
                      fontSize={'14px'}
                      fontWeight={500}
                      cursor={'pointer'}
                    >
                      Select Thumbnail
                    </Center>
                  )}
                </Files>
              </Center>
              <Input
                backgroundColor="#b5b5b5"
                placeholder="Title"
                _placeholder={{ color: 'gray.500' }}
                value={label}
                onChange={e => setLabel(e.target.value)}
              />
              <Textarea
                mt={'5px'}
                backgroundColor="#b5b5b5"
                placeholder={'Description'}
                _placeholder={{ color: 'gray.500' }}
                onChange={e => setDesc(e.target.value)}
                value={desc}
                // maxLength={charLimit}
              />
              <FormControl display="flex" alignItems="center">
                <CSwitch
                  size="md"
                  id="publish"
                  onChange={e => setPublished(e.target.checked)}
                  mr={'10px'}
                  isChecked={published}
                />
                <FormLabel htmlFor="publish" mb="0">
                  Publish this Package
                </FormLabel>
              </FormControl>

              <Alert status="warning">
                <AlertIcon />
                <AlertDescription display="block">
                  Please make sure that you have 3 Pause Points between
                  Interaction Points before exporting.
                </AlertDescription>
              </Alert>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={photoURL === ''}
              loadingText="Exporting..."
              isLoading={loading}
              spinnerPlacement="end"
              borderRadius={0}
              background="#87c94e"
              // _hover={{ background: '#87c94e' }}
              px={'30px'}
              onClick={() => {
                setLoading(true);
                store
                  ._exportPackage({
                    thumbnail: photo,
                    title: label,
                    description: desc,
                    video: data.video,
                    interaction_points: data.markers,
                    edit,
                    id: edit ? data.proj.id : null,
                    published,
                    subtitle: data.subtitle,
                    onUploadProgress: progress => {
                      // console.log('Progress', progress);
                      setProgress(progress);
                    },
                  })
                  .then(result => {
                    setLoading(false);
                    onClose();
                    onComplete(result);
                  })
                  .catch(error => {
                    setLoading(false);
                  });
              }}
            >
              Export
            </Button>
          </ModalFooter>
          <Progress colorScheme="green" hasStripe value={progress} />
        </ModalContent>
      </Modal>
    </>
  );
};

const NewPopup = ({ title, children, onComplete, placeholder }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('Untitled');

  const store = useContext(MainStore);

  const player = useRef(null);

  const [video, setVideo] = useState();
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [videoData, setVideoData] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(false);

  useEffect(() => {
    onOpen();
    return () => {};
  }, []);

  const onFilesChange = files => {
    // console.log('x', files);

    // console.log(JSON.stringify(files[0]));

    if (!files[0]) return;

    store._globalToast({
      title: 'Video loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    // console.log('PATH', URL.createObjectURL(files[0]));
    setVideo(files[0]);
    setVideoFilePath(URL.createObjectURL(files[0]));
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);

    store._globalToast({
      title: 'Error loading file',
      description: error.message,
      status: 'error',
    });
  };

  const onDuration = data => {
    setDuration(data);
    // console.log('duration', data);
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        // size="lg"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent maxW="46rem" borderRadius={0} backgroundColor="#f0f0f0">
          <ModalHeader
            backgroundColor="white"
            px={3}
            py={2}
            fontSize={16}
            fontWeight={400}
          >{`${title}`}</ModalHeader>
          <ModalCloseButton size="sm" as={Link} to="/" />
          <ModalBody>
            <HStack>
              <Text>Name:</Text>
              <Input
                backgroundColor="white"
                value={content}
                onChange={e => setContent(e.target.value)}
              />
            </HStack>
            <Flex color="black" mt={4} h="240px">
              <Stack flex="1" p={2} justifyContent="center">
                <Flex>
                  <Box w={'120px'}>
                    <Text>Width:</Text>
                    <Text>--</Text>
                  </Box>
                  <Box>
                    <Text>Height</Text>
                    <Text>--</Text>
                  </Box>
                </Flex>
                <Box>
                  <Text>Total Duration:</Text>
                  <Text>{duration ? msToTime(duration * 1000) : '--'}</Text>
                </Box>
                <Flex>
                  <Box w={'120px'}>
                    <Text>Frame Rate:</Text>
                    <Text>--</Text>
                  </Box>
                  <Box>
                    <Text>File Size:</Text>
                    <Text>{video ? video.sizeReadable : '--'}</Text>
                  </Box>
                </Flex>
              </Stack>

              <Box flex="2" backgroundColor="#b5b5b5">
                {videoFilePath == null ? (
                  <Files
                    style={{ height: '100%' }}
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={['video/mp4']}
                    multiple={false}
                    maxFileSize={500000000}
                    // minFileSize={0}
                    clickable
                  >
                    <VStack h="100%" justifyContent="center">
                      <Icon as={GiFilmStrip} w={70} h={70} color="white" />
                      <Text color="white" mt={4}>
                        Click the button below or drag and drop to add a video.
                      </Text>
                      <Text color="white" mt={2} fontSize={'12px'}>
                        Max file size is 500mb
                      </Text>
                      <Button
                        mt={4}
                        borderRadius={0}
                        background="#87c94e"
                        px={'30px'}
                        // _hover={{ opacity: 0.75 }}_
                        leftIcon={<AiOutlinePlus />}
                      >
                        ADD VIDEO
                      </Button>
                    </VStack>
                  </Files>
                ) : (
                  <ReactPlayer
                    ref={player}
                    url={videoFilePath}
                    controls={true}
                    width="100%"
                    height="100%"
                    playing={isPlaying}
                    onDuration={onDuration}
                  />
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              as={Link}
              to="/create"
              isDisabled={videoFilePath == null}
              borderRadius={0}
              background="#87c94e"
              px={'30px'}
              _hover={{ background: '#87c94e' }}
              onClick={() => {
                if (videoFilePath) {
                  onComplete({ video, content });
                  onClose();
                }
              }}
            >
              CREATE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const AlertPopup = ({ children, title, onComplete }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  return (
    <>
      <span onClick={() => setIsOpen(true)}>{children}</span>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background="#f0f0f0" borderRadius={0}>
            <AlertDialogHeader
              backgroundColor="white"
              px={3}
              py={2}
              fontSize={16}
              fontWeight={400}
            >
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? Any unsaved progress will be lost
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                borderRadius={0}
                px={'30px'}
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                borderRadius={0}
                background="#87c94e"
                px={'30px'}
                onClick={() => {
                  onClose();
                  onComplete();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
