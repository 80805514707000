/* eslint-disable import/no-anonymous-default-export */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Code,
  Grid,
  Center,
  Square,
  theme,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Switch as CSwitch,
  Wrap,
  WrapItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Progress,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';

import {
  FaUser,
  FaLock,
  FaTrash,
  FaEdit,
  FaMapMarkerAlt,
  FaImage,
  FaRegEye,
  FaRegEyeSlash,
} from 'react-icons/fa';

import { AiOutlinePlus } from 'react-icons/ai';
import { GiFilmStrip } from 'react-icons/gi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import moment from 'moment';

export default observer(() => {
  const store = useContext(MainStore);

  let { path, url } = useRouteMatch();

  useEffect(() => {
    store._getPackages();
    return () => {};
  }, []);

  return (
    <Box p={20}>
      <Wrap spacing="30px">
        <WrapItem>
          <Tooltip label={'Create New Package'} placement="bottom">
            {/* <NewPopup title="New Project" onComplete={data => {}}> */}
            <Square
              pos="relative"
              as={Link}
              to={'/create'}
              boxSize="200px"
              border="4px solid #87c94e"
              borderRadius={4}
              backgroundColor="white"
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
            >
              <Icon as={AiOutlinePlus} w={12} h={12} mb={5} />
              <Text pos="absolute" bottom={4} fontSize={14}>
                CREATE NEW
              </Text>
            </Square>
            {/* </NewPopup> */}
          </Tooltip>
        </WrapItem>
        {store.packages.map((p, i) => {
          return (
            <WrapItem key={`proj${i}`}>
              <Tooltip label={p.title} placement="bottom">
                <Box
                  as={Link}
                  to={`/edit/${p.id}`}
                  boxShadow="md"
                  backgroundColor="white"
                  w="200px"
                  h="200px"
                  maxW="200px"
                  maxH="200px"
                  cursor="pointer"
                  _hover={{ opacity: 0.8 }}
                >
                  <Flex
                    px={1}
                    h="18px"
                    color={p.published ? 'green' : 'red'}
                    justifyContent="flex-end"
                  >
                    {p.published ? (
                      <Icon as={FaRegEye} />
                    ) : (
                      <Icon as={FaRegEyeSlash} />
                    )}
                  </Flex>
                  {/* <Text
                    fontSize={12}
                    h={'18px'}
                    textAlign="center"
                    color={p.published ? 'green' : 'red'}
                  >
                    {p.published ? 'Published' : 'Not Published'}
                  </Text> */}
                  <ImageBox w="100%" h="120px" data={p} />
                  <Box px={4} py={2}>
                    <Text fontSize={18} fontWeight={400} isTruncated>
                      {p.title}
                    </Text>
                    {/* <Text>{`${moment(p.updated_at).fromNow()}`}</Text> */}
                    <Text fontSize={12}>{`Updated: ${moment(
                      p.updated_at
                    ).fromNow()}`}</Text>
                  </Box>
                </Box>
              </Tooltip>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
});

const ImageBox = ({ data, ...props }) => {
  const [loading, setLoading] = useState(true);
  return (
    <Box {...props}>
      {loading && (
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      )}
      <Image
        visibility={loading ? 'hidden' : 'visible'}
        w="100%"
        h="100%"
        objectFit="cover"
        src={data.thumbnail ? data.thumbnail.formats.thumbnail.url : null}
        alt={data.title}
        ignoreFallback
        onLoad={() => setLoading(false)}
      />
    </Box>
  );
};

function msToTime(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
}

const NewPopup = ({ title, children, onComplete, placeholder }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('Untitled');

  const store = useContext(MainStore);

  const player = useRef(null);

  const [video, setVideo] = useState();
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [videoData, setVideoData] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(false);

  useEffect(() => {
    onOpen();
    return () => {};
  }, []);

  const onFilesChange = files => {
    console.log(files);

    console.log(JSON.stringify(files[0]));

    store._globalToast({
      title: 'Video loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    console.log('PATH', URL.createObjectURL(files[0]));
    setVideo(files[0]);
    setVideoFilePath(URL.createObjectURL(files[0]));
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  const onDuration = data => {
    setDuration(data);
    console.log('duration', data);
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        // size="lg"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent maxW="46rem" borderRadius={0} backgroundColor="#f0f0f0">
          <ModalHeader
            backgroundColor="white"
            px={3}
            py={2}
            fontSize={16}
            fontWeight={400}
          >{`${title}`}</ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <HStack>
              <Text>Name:</Text>
              <Input
                backgroundColor="white"
                value={content}
                onChange={e => setContent(e.target.value)}
              />
            </HStack>
            <Flex color="black" mt={4} h="240px">
              <Stack flex="1" p={2} justifyContent="center">
                <Flex>
                  <Box w={40}>
                    <Text>Width:</Text>
                    <Text>--</Text>
                  </Box>
                  <Box>
                    <Text>Height</Text>
                    <Text>--</Text>
                  </Box>
                </Flex>
                <Box>
                  <Text>Total Duration:</Text>
                  <Text>{duration ? msToTime(duration * 1000) : '--'}</Text>
                </Box>
                <Flex>
                  <Box w={40}>
                    <Text>Frame Rate:</Text>
                    <Text>--</Text>
                  </Box>
                  <Box>
                    <Text>File Size:</Text>
                    <Text>{video ? video.sizeReadable : '--'}</Text>
                  </Box>
                </Flex>
              </Stack>

              <Box flex="2" backgroundColor="#b5b5b5">
                {videoFilePath == null ? (
                  <Files
                    style={{ height: '100%' }}
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={['video/mp4']}
                    multiple={false}
                    // maxFileSize={10000000}
                    // minFileSize={0}
                    clickable
                  >
                    <VStack h="100%" justifyContent="center">
                      <Icon as={GiFilmStrip} w={70} h={70} color="white" />
                      <Text color="white">
                        Click the button below or drag and drop to add a vdeio.
                      </Text>
                      <Button
                        borderRadius={0}
                        background="#87c94e"
                        px={'30px'}
                        _hover={{ opacity: 0.75 }}
                        leftIcon={<AiOutlinePlus />}
                      >
                        ADD VIDEO
                      </Button>
                    </VStack>
                  </Files>
                ) : (
                  <ReactPlayer
                    ref={player}
                    url={videoFilePath}
                    controls={true}
                    width="100%"
                    height="100%"
                    playing={isPlaying}
                    onDuration={onDuration}
                  />
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              as={Link}
              to="/create"
              isDisabled={videoFilePath == null}
              borderRadius={0}
              background="#87c94e"
              px={'30px'}
              _hover={{ background: '#87c94e' }}
              onClick={() => {
                onComplete(content);
                onClose();
              }}
            >
              CREATE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
