import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Code,
  Grid,
  Center,
  theme,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { FaUser, FaLock, FaUserCircle, FaUserTie } from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from './stores/MainStore';

//sreens
import UserScreen from './User/index';
import HomeScreen from './Home/index';
import LoginScreen from './Login/index';

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box h="100vh" bg="#f0f0f0">
          <AuthButton />
          <Switch>
            <Route path="/login">
              <LoginScreen />
            </Route>
            {/* <Route path="/">
              <Main />
            </Route> */}
            <PrivateRoute path="/">
              <Main />
            </PrivateRoute>
          </Switch>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

const AuthButton = observer(() => {
  let history = useHistory();
  let auth = useContext(MainStore);

  return auth.userData ? (
    <Flex backgroundColor="#ffffff" px={8} py={4} boxShadow="sm">
      <Box>
        <Text></Text>
      </Box>
      <Spacer />
      <HStack spacing={4}>
        <Text>{`Hello, ${auth.userData.user.username}`}</Text>
        <Menu>
          <MenuButton
            as={Box}
            cursor="pointer"
            boxShadow="base"
            // border="1px solid black"
            // border="1px solid black"
          >
            <Icon
              as={FaUser}
              w={8}
              h={8}
              color="white"
              backgroundColor="gray.500"
              p={'4px'}
            />
          </MenuButton>
          <MenuList>
            <AlertPopup
              title="Logout"
              onComplete={() => {
                auth
                  ._logout(() => console.log('logging out'))
                  .then(res => history.push('/'));
              }}
            >
              <MenuItem color="red">Logout</MenuItem>
            </AlertPopup>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  ) : (
    <></>
  );
});

// const AuthButton = observer(() => {
//   let history = useHistory();
//   let auth = useContext(MainStore);

//   return auth.userData ? (
//     <Box padding={2}>
//       <HStack>
//         <Text>{`Welcome, ${auth.userData.user.username}`}</Text>

//         <Button
//           onClick={() => {
//             auth
//               ._logout(() => console.log('logging out'))
//               .then(res => history.push('/'));
//           }}
//         >
//           Sign out
//         </Button>
//       </HStack>
//     </Box>
//   ) : (
//     <></>
//   );
// });

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = observer(({ children, ...rest }) => {
  const auth = useContext(MainStore);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.userData ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

// const LoginPage = observer(() => {
//   let history = useHistory();
//   let location = useLocation();
//   let auth = useContext(MainStore);

//   const [username, setusername] = useState('creator');
//   const [password, setpassword] = useState('Password1');

//   const [loading, setLoading] = useState(false);

//   let { from } = location.state || { from: { pathname: '/' } };
//   let login = () => {
//     setLoading(true);
//     auth._login({ username, password }).then(res => {
//       // history.replace(from);
//       setLoading(false);
//       history.replace('/');
//     });
//   };

//   return (
//     <Box p={4} maxW="sm" mx="auto">
//       <VStack spacing={2}>
//         <InputGroup>
//           <InputLeftElement
//             pointerEvents="none"
//             children={<FaUser color="gray.300" />}
//           />
//           <Input
//             value={username}
//             onChange={e => setusername(e.target.value)}
//             type="text"
//             placeholder="Username"
//           />
//         </InputGroup>

//         <InputGroup>
//           <InputLeftElement
//             pointerEvents="none"
//             children={<FaLock color="gray.300" />}
//           />
//           <Input
//             value={password}
//             onChange={e => setpassword(e.target.value)}
//             type="password"
//             placeholder="Password"
//           />
//         </InputGroup>

//         <Button loadingText="Logging in..." isLoading={loading} onClick={login}>
//           Login
//         </Button>
//       </VStack>
//     </Box>
//   );
// });

const LoginPage = observer(() => {
  let history = useHistory();
  let location = useLocation();
  let auth = useContext(MainStore);

  const [username, setusername] = useState('creator');
  const [password, setpassword] = useState('Password1');

  const [loading, setLoading] = useState(false);

  let { from } = location.state || { from: { pathname: '/' } };
  let login = () => {
    setLoading(true);
    auth._login({ username, password }).then(res => {
      // history.replace(from);
      setLoading(false);
      history.replace('/');
    });
  };

  return (
    <Box p={4} maxW="sm" mx="auto">
      <VStack spacing={2}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaUser color="gray.300" />}
          />
          <Input
            value={username}
            onChange={e => setusername(e.target.value)}
            type="text"
            placeholder="Username"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaLock color="gray.300" />}
          />
          <Input
            value={password}
            onChange={e => setpassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
        </InputGroup>

        <Button loadingText="Logging in..." isLoading={loading} onClick={login}>
          Login
        </Button>
      </VStack>
    </Box>
  );
});

function Main() {
  let history = useHistory();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    //force here depending on user type
    history.replace('/');
    return () => {};
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <HomeScreen />
      </Route>
      <Route path={'/create'}>
        <UserScreen />
      </Route>
      <Route path={'/edit'}>
        <UserScreen />
      </Route>
      <Route path={'/order-history'}>
        <Text>order history</Text>
      </Route>
    </Switch>
  );
}

const AlertPopup = ({ children, title, onComplete }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  return (
    <>
      <span onClick={() => setIsOpen(true)}>{children}</span>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background="#f0f0f0" borderRadius={0}>
            <AlertDialogHeader
              backgroundColor="white"
              px={3}
              py={2}
              fontSize={16}
              fontWeight={400}
            >
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? Any unsaved progress will be lost
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                borderRadius={0}
                px={'30px'}
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                borderRadius={0}
                background="#87c94e"
                px={'30px'}
                onClick={() => {
                  onClose();
                  onComplete();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
