import { createContext } from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import io from 'socket.io-client';

import VTTConverter from 'srt-webvtt';
import { GiConsoleController } from 'react-icons/gi';

const toast = createStandaloneToast();

export class Main {
  // baseURL = 'http://localhost:1337';
  // baseURL = 'https://packager-admin.herokuapp.com';
  baseURL = 'https://cms.watchwriteread.com';
  userData = null;
  packages = [];
  alertClosed = false;

  constructor() {
    //check if logged in
    this.userData = JSON.parse(window.sessionStorage.getItem('userData'));
    // console.log(toJS(this.userData));

    //socket io
    // const socket = io(this.baseURL);
    // socket.on('request_update', data => {});

    makeAutoObservable(this);
  }

  _hideAlert = () => {
    this.alertClosed = true;
  };

  _srtToVtt = async url => {
    const srt = await axios.get(url, { responseType: 'blob' });
    const vttConverter = new VTTConverter(srt.data);
    return vttConverter;
  };

  _srtFileToVtt = async file => {
    const vttConverter = new VTTConverter(file);
    return vttConverter;
  };

  _globalToast = params => {
    toast(params);
  };

  _login = params => {
    // this.userData = { user: { username: params.username } };
    // window.sessionStorage.setItem('userData', JSON.stringify(this.userData));

    // this._globalToast({
    //   title: 'Login successful',
    //   status: 'success',
    // });

    // return Promise.resolve();
    return axios
      .post(`${this.baseURL}/auth/local`, {
        identifier: params.username,
        password: params.password,
      })
      .then(response => {
        console.log('Login Success!', response);
        window.sessionStorage.setItem(
          'userData',
          JSON.stringify(response.data)
        );
        this.userData = response.data;
        this._globalToast({
          title: 'Login successful',
          status: 'success',
        });
      })
      .catch(err => {
        // console.log(err.response);

        const errMsg = err.response.data.message[0].messages[0].message;

        this._globalToast({
          title: 'Unable to login',
          status: 'error',
          description: errMsg,
        });
      });
  };

  _logout = cb => {
    this.userData = null;
    window.sessionStorage.removeItem('userData');
    cb();

    this._globalToast({
      title: 'You have been logged out.',
      status: 'success',
    });

    return Promise.resolve();
  };

  _exportPackage = params => {
    const formData = new FormData();

    //process interaction points here
    let interaction_points = [...params.interaction_points];
    interaction_points = interaction_points.map((ip, i) => {
      const tempIP = {
        label: `Interaction Point ${i}`,
        pause_at: `${ip.timestamp.substring(0, 8)},${ip.timestamp.substr(
          9,
          13
        )}`,
        content: `${ip.content} ${ip.selected
          .map(word => `\t${word}`)
          .join('')}`,
        pause_point: ip.pause_point,
        Stickers: ip.stickers.map((s, j) => {
          //files.my_component_name[the_index].attribute_name

          const sticker = {};
          sticker.word = s.word;

          if (s.imageFile) {
            formData.append(
              `files.interaction_points[${i}].Stickers[${j}].image`,
              s.imageFile
            );
          } else {
            sticker.image = { ...s.image };
          }

          return sticker;
        }),
      };
      return tempIP;
    });

    if (!params.edit) formData.append('files.video', params.video);
    if (params.thumbnail) formData.append('files.thumbnail', params.thumbnail);
    if (params.subtitle) formData.append('files.subtitle', params.subtitle);

    formData.append(
      'data',
      JSON.stringify({
        title: params.title,
        description: params.description,
        interaction_points,
        published: params.published,
      })
    );

    return new Promise((resolve, reject) => {
      axios({
        method: params.edit ? 'put' : 'post',
        url: `${this.baseURL}/packages${params.edit ? '/' + params.id : ''}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
        data: formData,
        onUploadProgress: progressEvent => {
          // console.log(
          //   progressEvent.loaded / params.video.size,
          //   progressEvent.loaded,
          //   '/',
          //   params.video.size
          // );

          // (progressEvent.loaded / params.video.size) * 100

          params.onUploadProgress(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      })
        .then(async response => {
          console.log('RES', response);

          await this._getPackages();

          resolve(response);

          this._globalToast({
            title: 'Package exported successfully!',
            status: 'success',
          });
        })
        .catch(err => {
          console.log(err.response);

          reject(err);

          // const errMsg = err.response.data.message[0].messages[0].message;

          this._globalToast({
            title: 'Export failed',
            status: 'error',
            description: 'Please try again later.',
            // description: errMsg,
          });
        });
    });
  };

  _getPackages = params => {
    return axios({
      method: 'get',
      url: `${this.baseURL}/getpackages?_sort=updated_at:DESC`,
      headers: {
        Authorization: `Bearer ${this.userData.jwt}`,
      },
    })
      .then(response => {
        console.log('RES', response);

        this.packages = response.data;

        // if (params) {
        //   this._globalToast({
        //     title: `${response.data.length} Packages has been loaded`,
        //     status: 'success',
        //     // description: 'Please try again later.',
        //   });
        // }
      })
      .catch(err => {
        console.log(err.response);
      });
  };

  _getPackage = params => {
    console.log(toJS(this.packages));
    return this.packages.find(x => x.id === parseInt(params));
  };

  _createOrder = params => {
    // return axios({
    //   method: 'post',
    //   url: `${this.baseURL}/orders`,
    //   headers: {
    //     Authorization: `Bearer ${this.userData.data.jwt}`,
    //   },
    //   data: {
    //     order: params.puDetails,
    //     parcels: params.orderDetails,
    //   },
    // })
    //   .then(response => {
    //     console.log('RES', response);
    //   })
    //   .catch(err => {
    //     console.log(err.response);
    //   });
  };
}

export default createContext(new Main());
