/* eslint-disable import/no-anonymous-default-export */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  VStack,
  Code,
  Grid,
  Center,
  Square,
  theme,
  useDisclosure,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Switch as CSwitch,
  Wrap,
  WrapItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Progress,
  SimpleGrid,
} from '@chakra-ui/react';

import {
  FaUser,
  FaLock,
  FaTrash,
  FaEdit,
  FaMapMarkerAlt,
  FaImage,
} from 'react-icons/fa';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import logo from '../assets/logo.png';

export default observer(() => {
  let history = useHistory();
  let location = useLocation();
  let auth = useContext(MainStore);

  const [username, setusername] = useState('creator');
  const [password, setpassword] = useState('Password1');

  const [loading, setLoading] = useState(false);

  let { from } = location.state || { from: { pathname: '/' } };
  let login = () => {
    setLoading(true);
    auth._login({ username, password }).then(res => {
      // history.replace(from);
      setLoading(false);
      history.replace('/');
    });
  };

  return (
    <Flex
      height={'100vh'}
      // flexDir={"column"}
    >
      <Center p={'10px'} flex="1" justifyContent="flex-end">
        <Image w="100%" src={logo}></Image>
      </Center>
      <Center p={'10px'} flex="1" justifyContent="center">
        <Stack w="80%" alignItems="flex-end">
          <InputGroup>
            {/* <InputLeftElement
              pointerEvents="none"
              children={<FaUser color="gray.300" />}
            /> */}
            <Input
              border="2px solid #969696 !important"
              background="#b5b5b5"
              value={username}
              onChange={e => setusername(e.target.value)}
              type="text"
              placeholder="Account: "
              _placeholder={{ color: '#969696' }}
            />
          </InputGroup>

          <InputGroup>
            {/* <InputLeftElement
              pointerEvents="none"
              children={<FaLock color="gray.300" />}
            /> */}
            <Input
              border="2px solid #969696 !important"
              background="#b5b5b5"
              value={password}
              onChange={e => setpassword(e.target.value)}
              type="password"
              placeholder="Password:"
              _placeholder={{ color: '#969696' }}
            />
          </InputGroup>

          <Button
            borderRadius={0}
            background="#87c94e"
            px={'30px'}
            _hover={{ opacity: 0.75 }}
            loadingText="Logging in..."
            isLoading={loading}
            onClick={login}
          >
            LOG IN
          </Button>
        </Stack>
      </Center>
    </Flex>
  );
});
